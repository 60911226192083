import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
  Spinner, makeStyles
} from '@fluentui/react-components';
import React, { Suspense, useState } from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import './i18n';  // Import the i18n configuration
import HeroSection from './HeroSection';

// Lazy load components
const AboutMe = React.lazy(() => import('./AboutMe'));
const CertificationTimeline = React.lazy(() => import('./Certification'));
const ProjectsSection = React.lazy(() => import('./ProjectSection'));
const SideNav = React.lazy(() => import('./SideNav'));
const ExperienceTimeline = React.lazy(() => import('./Timeline'));
const ContactSection = React.lazy(() => import('./ContactSection'));

const useStyles = makeStyles({
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'inherit'
  }
});

function App() {
  const { t } = useTranslation();
  const getSystemTheme = () => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };
  const [isDarkMode, setIsDarkMode] = useState(getSystemTheme());
  const [isNavOpen, setIsNavOpen] = useState(window.innerWidth > 768);
  const currentTheme = isDarkMode ? webDarkTheme : webLightTheme;
  const styles = useStyles();
  // Handle theme toggle
  const toggleTheme = (darkMode) => {
    setIsDarkMode(darkMode);
  };
  return (
    <FluentProvider theme={currentTheme}>
      <Suspense fallback={
        <div className={styles.loadingContainer}>
          <Spinner size="extra-large" labelPosition="below" label={t('states.loading')} />
        </div>
      }>
        <div style={{ display: 'flex' }}>
          {/* Fixed SideNav, ensure it has a high zIndex via fixedStyle in SideNav.js */}
          <SideNav isFixed={true} isOpen={isNavOpen} setIsOpen={setIsNavOpen} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
          <div style={{ display: 'flex', flexGrow: 1 }}>
            {/* Non-fixed SideNav, disable pointer events to make it non-interactable */}
            {!isMobileDevice() && (
              <div>
                <SideNav isOpen={isNavOpen} setIsOpen={setIsNavOpen} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
              </div>
            )}
            <main style={{ flexGrow: 1, overflowY: 'auto' }}>
              <HeroSection />
              <Suspense>
                <AboutMe />
                <ExperienceTimeline />
                <CertificationTimeline />
                <ProjectsSection />
                <ContactSection />
              </Suspense>
              {/* Other content... */}
            </main>
          </div>
        </div>
      </Suspense>
    </FluentProvider>
  );
}

export default App;