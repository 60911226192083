import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import {
    makeStyles, tokens, Title1,
    Title2
} from '@fluentui/react-components';
import heroBg from './assets/hero-bg.webp';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    heroSection: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${heroBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `color-mix(in srgb, ${tokens.colorNeutralBackground2} 50%, transparent)`,
    },
    content: {
        position: 'relative',
        zIndex: 1,
        textAlign: 'center',
        color: tokens.colorNeutralForeground1,
    },
    title1: {
        fontSize: '4rem',
        marginBottom: '0.5rem',
    },
    title2: {
        fontSize: '2.5rem',
        fontWeight: 'normal',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
    },
    staticText: {
        whiteSpace: 'nowrap',
    },
    typingText: {
        color: tokens.colorBrandForeground1,
        textAlign: 'center',
        minWidth: '200px',
        '@media (max-width: 600px)': {
            width: '100%',
        },
    }
});

const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.6,
            ease: "easeOut"
        }
    }
};

const stagger = {
    visible: { transition: { staggerChildren: 0.2 } }
};

const HeroSection = () => {
    const styles = useStyles();
    const { t, i18n } = useTranslation();

    // Memoize the sequence to prevent re-renders and recursion
    const typingSequence = useMemo(() => [
        t('hero.softwareEngineer'),
        2000,
        t('hero.powerPlatformEngineer'),
        2000,
        t('hero.problemSolver'),
        2000,
        t('hero.fullStackDeveloper'),
        2000,
    ], [i18n.language]);

    return (
        <section id="home1" className={styles.heroSection}>
            <div className={styles.overlay} />
            <motion.div
                className={styles.content}
                initial="hidden"
                animate="visible"
                variants={stagger}
            >
                <motion.div variants={fadeInUp}>
                    <Title1 className={styles.title1}>{t('hero.name')}</Title1>
                </motion.div>
                <motion.div variants={fadeInUp}>
                    <Title2 className={styles.title2}>
                        <span className={styles.staticText}>{t('hero.staticText')}</span>
                        {/* Add a key prop to force re-mounting when language changes */}
                        <TypeAnimation
                            key={i18n.language} // Force remount on language change
                            sequence={typingSequence} // Dynamically set sequence
                            wrapper="span"
                            speed={50}
                            repeat={Infinity}
                            className={styles.typingText}
                        />
                    </Title2>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default HeroSection;
